import React, { useState } from 'react'

import { Layout } from '../../components/auth/Layout'
import { SignUpContent } from '../../components/auth/SignUpContent'
import { SignUpOnboarding } from '../../components/auth/SignUpOnboarding'
import { AccountType } from '../../requests'

const IndexPage = () => {
  const [isOnboarded, setIsOnboarded] = useState(false)
  return isOnboarded ? (
    <Layout accountType={AccountType.Subject}>
      <SignUpContent accountType={AccountType.Subject} />
    </Layout>
  ) : (
    <SignUpOnboarding setIsOnboarded={setIsOnboarded} />
  )
}

export default IndexPage
export const Head = () => <title>Datacapt - Register</title>
