import './SignUpOnboarding.less'

import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { use100vh } from 'react-div-100vh'
import StepWizard, { StepWizardChildProps } from 'react-step-wizard'

import ArrowRight from '../../../assets/images/arrow-right.svg'
import ImageSlide1 from '../../../assets/images/onboarding-slide1.svg'
import ImageSlide2 from '../../../assets/images/onboarding-slide2.svg'
import ImageSlide3 from '../../../assets/images/onboarding-slide3.svg'
import { useScopedIntl } from '../../../hooks'
import { DatacIcon, DatacLanguageSelector, DatacTitle } from '../../common'

const slideImages = [ImageSlide1, ImageSlide2, ImageSlide3]

const Slide: React.VFC<{ slideNumber: number }> = ({ slideNumber }) => {
  const SlideImage = slideImages[slideNumber - 1]
  return (
    <div className="signup-onboarding__slide">
      <SlideImage />
    </div>
  )
}

interface OnboardingStaticElementsProps extends Partial<StepWizardChildProps> {
  setIsOnboarded: () => void
}

const OnboardingStaticElements: React.VFC<OnboardingStaticElementsProps> = ({
  nextStep,
  currentStep,
  totalSteps,
  goToStep,
  setIsOnboarded
}) => {
  const intlSlide = useScopedIntl('subject_onboarding.slide')

  return (
    <div className="signup-onboarding__static-elements">
      <div className="signup-onboarding__content">
        <div className="signup-onboarding__description">
          <DatacTitle>{intlSlide(`${currentStep}.title`)}</DatacTitle>
          {intlSlide(`${currentStep}.description`)}
        </div>
        <div className="signup-onboarding__nav">
          <div>
            {Array.from({ length: totalSteps }, (_, i) => i + 1).map(slideNumber => (
              <span
                key={`step-${slideNumber}`}
                className={`signup-onboarding__nav__dot ${
                  currentStep === slideNumber ? 'signup-onboarding__nav__dot-active' : ''
                }`}
                onClick={() => goToStep(slideNumber)}
              >
                &nbsp;
              </span>
            ))}
          </div>
          <Button
            className="auth-form-submit-button"
            type="primary"
            onClick={currentStep === 3 ? setIsOnboarded : nextStep}
          >
            {intlSlide(`${currentStep}.button`)}
            <ArrowRight className="auth-form-submit-button__arrow" />
          </Button>
        </div>
      </div>
    </div>
  )
}

interface SignUpOnboardingProps {
  setIsOnboarded: (isOnboarded: boolean) => void
}
export const SignUpOnboarding: React.VFC<SignUpOnboardingProps> = ({ setIsOnboarded }) => {
  const intlSlide = useScopedIntl('subject_onboarding.slide')
  const maxHeight = use100vh() // max height that work correctly on ios

  // fix for step wizard flickering on first render
  const [isRendered, setIsRendered] = useState(false)
  useEffect(() => {
    setIsRendered(true)
  }, [])

  return (
    isRendered && (
      <div className="signup-onboarding" style={{ height: maxHeight }}>
        <div className="signup-onboarding__header">
          <Button type="text" className="signup-onboarding__header__skip" onClick={() => setIsOnboarded(true)}>
            {intlSlide('skip')}
          </Button>
          <DatacIcon raw name="datac" className="signup-onboarding__header__logo" />
          <DatacLanguageSelector />
        </div>
        <StepWizard
          className="signup-onboarding__wizard"
          nav={<OnboardingStaticElements setIsOnboarded={() => setIsOnboarded(true)} />}
        >
          {[1, 2, 3].map(slideNumber => (
            <Slide slideNumber={slideNumber} key={slideNumber} />
          ))}
        </StepWizard>
      </div>
    )
  )
}
